import React, { useContext } from 'react'
import { Flex } from './Flex';
import { LanguageContext } from '../layout/Layout';
import { Hover } from './Hover';
import { Paragraph } from './Paragraph';
import { useStaticQuery, Link, graphql } from "gatsby"
import styled from 'styled-components';

const q = graphql`
query NextPrevCaseQuery {
    cases: allSanityCase {
      edges {
        node {
          id
          title {
            nb
            en
          }
          slug {
            _key
            _type
            current
          }
        }
      }
    }
  }
`

Number.prototype.mod = function(n) {
  return ((this%n)+n)%n;
  }

const Spacer = styled.div`
flex-grow: 1;
height: 100%;
`

export const NextPrevCase = ({ current = '' }) => {

    const lang = useContext(LanguageContext);
    
    const data = useStaticQuery(q);
    const cases = data.cases.edges.map(e => e.node);
    const caseIds = cases.map(c => c.id);
    const currentIndex = caseIds.indexOf(current);
    
    const prevIndex = (currentIndex - 1).mod(cases.length);
    const nextIndex = (currentIndex + 1).mod(cases.length);

    const prevCaseTitle = cases[prevIndex].title[lang];
    const prevCaseSlug = cases[prevIndex].slug.current;
    const nextCaseTitle = cases[nextIndex].title[lang];
    const nextCaseSlug = cases[nextIndex].slug.current;
    
    const prevText = lang === `nb` ? 'Forrige case' : 'Previous case';
    const nextText = lang === `nb` ? 'Neste case' : 'Next case';

    return (
        <Flex wrap justify="space-between" style={{ width: '100%' }}>
                <CaseLink heading={prevCaseTitle} subtitle={prevText} slug={prevCaseSlug} back></CaseLink>
                <Spacer></Spacer>
                <CaseLink heading={nextCaseTitle} subtitle={nextText} slug={nextCaseSlug} next style={{ marginLeft: 'auto', }} styleInner={{alignItems: 'flex-end'}}></CaseLink>
        </Flex>
    )
}

const CaseLink = ({ heading, subtitle, slug, styleInner, style, next, back }) => {
    
    const lang = useContext(LanguageContext);
    const url = `/${lang}/case/${slug}`;

    return (
        <Link to={url} style={style} state={{ goingNext: next, goingBack: back }}>
            <Hover>
                <Flex column style={{ ...styleInner }}>
                    <Paragraph size="label"  weight="normal" style={{  margin: 0 }}>{subtitle}</Paragraph>
                    <Paragraph size="ingress" weight="bold" style={{ margin: 0 }}>{heading}</Paragraph>
                </Flex>
            </Hover>
        </Link>
    )
}