import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { usePageTitle } from '../hooks/usePageTitle';
import { BlockContent } from '../components/block-content';
import HeaderPadding from '../layout/HeaderPadding';
import { LanguageContext } from '../layout/Layout';
import { NextPrevCase } from '../components/NextPrevCase';
import SEO from '../components/seo';
import useSanityImage from '@hooks/useSanityImage';
import useOGImage from '@hooks/useOGImage';
import useGetBlock from '@hooks/useGetBlock';

export const query = graphql`
  query CaseTemplateQuery($id: String!) {
    case: sanityCase(id: { eq: $id }) {
      id
      title {
        nb
        en
      }
      image {
        asset {
          url
        }
      }
      _rawBody
    }
  }
`;

const Case = ({ data: { case: project } }) => {
  const lang = useContext(LanguageContext);
  const title = (project.title && project.title[lang]) || (project.title && project.title['nb']);
  const body = (project._rawBody && project._rawBody[lang]) || (project._rawBody && project._rawBody['nb']) || [];
  usePageTitle(title);

  const description = useGetBlock(body);
  const ogImage = useOGImage(project.image);
  return (
    <React.Fragment>
      <SEO title={title} description={description} {...ogImage} />
      <HeaderPadding />
      <BlockContent blocks={body} />
      <HeaderPadding />
      <NextPrevCase current={project.id} />
    </React.Fragment>
  );
};

export default Case;
